<template>
  <div>
      <Navbar/>
      <BillingTable/>
  </div>
</template>

<script>
import Navbar from '../components/Navbar'
import BillingTable from '../components/BillingTable'
export default {
    components: {
        Navbar,
        BillingTable
    }
}
</script>

<style>

</style>