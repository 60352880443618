<template>
  <div id="app">
    <v-app id="inspire">
      <v-data-table
        :headers="billingTable.headers"
        :items="billingTable.billings"
        item-key="ext_usr_id"
        class="elevation-1"
        :loading="billingTable.loading"
        loading-text="Cargando..."
        no-data-text="No data found"
        dense
        :search="billingTable.search"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-spacer></v-spacer>
              <SearchBar :setSearchTerm="setTableSearchTerm" />
            </v-row>
          </v-toolbar>
        </template>
      </v-data-table>
      <FAB
        :buttons="[
          {
            fn: openDialogActivateBill,
            icon: 'mdi-credit-card-check-outline',
            color: 'green',
            name: 'Activar licencia',
          },
        ]"
      />
      <ActivateBill :CloseFn="closeDialogActivateBill"/>
    </v-app>
  </div>
</template>
<script>
import ActivateBill from "./Dialogs/Bills/ActivateBill";
import { mapActions, mapMutations, mapState } from "vuex";
import FAB from "../components/StandardizedComponents/FAB";
import SearchBar from "../components/Complements&Buttons/searchBar";

export default {
  data: () => ({
    hidden: false,
  }),
  components: {
    ActivateBill,
    FAB,
    SearchBar,
  },
  computed: {
    ...mapState("Billing", ["billingTable"]),
  },

  methods: {
    ...mapMutations("Billing", ["setTableSearchTerm","openDialogActivateBill","closeDialogActivateBill"]),
    ...mapActions("Billing", ["getTableBilling"]),
  },
  created() {
    this.getTableBilling();
  },
};
</script>