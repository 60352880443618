<template>
  <v-dialog :value="dialogActivateBill" :persistent="true" max-width="600" :scrollable='true'>
        <v-card outlined>
            <v-card-title>
                <span class="headline">Activar licencia</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row dense>
                        <v-col cols="10" class="mx-auto" >
                            <AutoComplete :Fn="updateDropdownBill" :Rules='[]' :GetValues="getDropdownInactiveBills" :Value="dropdownBill" Label="Licencias inactivas" :Items="dropdownBills" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="CloseFn"
                >
                    Close
                </v-btn>
                <v-btn
                    v-if="dropdownBill"
                    color="green"
                    text
                    @click="activateBill"
                >
                    Activar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import AutoComplete from '../../StandardizedComponents/autocompleteFields'
export default {
    props:{
        CloseFn: Function
    },
    components:{
        AutoComplete
    },
    computed:{
        ...mapState('Billing',['dialogActivateBill','dropdownBills','dropdownBill'])
    },
    methods:{
        ...mapActions('Billing',['activateBill','getDropdownInactiveBills']),
        ...mapMutations('Billing',['updateDropdownBill'])
    }
}
</script>

<style>

</style>